<template>
  <article-page :menu="menu">
    <div class="training-content" id="company-selector">
      <div class="_subtitle">
        Селектор компаний
      </div>
      <span>
        Для начала работы введите в селектор наименование, ИНН, КПП или адрес поставщика/заказчика и выберите нужную компанию из списка.
      </span>
      <article-image :src="images[0].src" :alt="images[0].alt" @click="openViewer(images, 0)"/>
    </div>
    <div class="training-content" id="company-filters">
      <div class="_subtitle">
        Расширенные фильтры
      </div>
      <span>
        Для уточнения запроса, Вы можете воспользоваться расширенными фильтрами.
        Для открытия формы с фильтрами необходимо нажать кнопку "Раширенный анализ".
      </span>
      <article-image :src="images[1].src" :alt="images[1].alt" @click="openViewer(images, 1)"/>
      <span>
        Расширенный режим позволяет уточнить поиск по таким критериям, как:
      </span>
      <ul>
        <li>Отрасли;</li>
        <li>Ключевые слова;</li>
        <li>Регионы;</li>
        <li>КПП филиала;</li>
        <li>Поиск по всем филиалам;</li>
        <li>Площадки;</li>
        <li>Дата публикации;</li>
        <li>Типы закупок;</li>
        <li>НМЦ (Начальная цена);</li>
        <li>Категории ОКПД;</li>
        <li>Заказчики;</li>
      </ul>
      <alert-message full-info type="warning" style="margin-top: 12px;">
        <template #content>
          Обратите внимание, поиск по дереву отраслей работает корректно только при указании даты публикации тендера не ранее, чем 01.01.2020.
          Мы работаем над расширением классификации отраслей, поэтому пожалуйста, воспользуйтесь иными фильтрами при поиске за более ранний период.
        </template>
      </alert-message>
      <span>
        Изначально аналитика происходит по всем филиалам.
        Для получения аналитики по конкретному филиалу, введите нужный КПП в соответствующий фильтр,
        или выберите нужный филиал в списке найденных компаний.
      </span>
      <article-image :src="images[2].src" :alt="images[2].alt" @click="openViewer(images, 2)"/>
      <article-image :src="images[3].src" :alt="images[3].alt" @click="openViewer(images, 3)"/>
    </div>
    <div class="training-content" id="company-results">
      <div class="_subtitle">
        Возможности аналитики
      </div>
      <span>
        Полный анализ компании по участию в государственных и коммерческих торгах:
      </span>
      <ul>
        <li>Общие объемы тендеров</li>
        <li>Сезонность размещения тендеров (по годам, по месяцам)</li>
        <li>Все заказчики/поставщики и их объемы публикаций тендеров</li>
        <li>Выгрузка данных для расширенной обработки в Excel</li>
      </ul>
      <article-image :src="images[4].src" :alt="images[4].alt" @click="openViewer(images, 4)"/>
    </div>
  </article-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ArticlePage from "@/components/pages/training/ArticlePage.vue";
import { useImageViewer } from "@/use/other/useImageViewer";
import { Tabs } from "@/router/tabs";
import ArticleImage from "@/components/pages/training/ArticleImage.vue";
import AlertMessage from "~/components/ui/alert/AlertMessage.vue";

export default defineComponent({
  name: "AnalyticsCompanyArticle",
  components: {
    AlertMessage,
    ArticleImage,
    ArticlePage,
  },
  setup() {

    const menu = [
      { id: 'company-selector', title: 'Селектор компаний' },
      { id: 'company-filters', title: 'Расширенные фильтры' },
      { id: 'company-results', title: 'Возможности аналитики' },
    ]

    const images = [
      { src: 'analytics/company-selector.png', alt: 'Введите данные компании и выберите нужную из списка' },
      { src: 'analytics/advanced-search.png', alt: 'Расширенный анализ' },
      { src: 'analytics/kpp-filter.png', alt: 'Фильтр по КПП' },
      { src: 'analytics/need-this-office.png', alt: 'Аналитика по филиалу' },
      { src: 'analytics/analytics-results.png', alt: 'Сортировка результатов' },
    ]

    const { openViewer } = useImageViewer();

    return {
      menu,
      images,
      Tabs,
      openViewer,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/pages/training';
</style>
